import { React, useState, useEffect } from "react";
import { Box, Flex, Button} from '@chakra-ui/react';
import { MdDeleteOutline, MdEdit, MdPerson, MdPersonOff} from "react-icons/md";
import Card from "components/card/Card.js";
import SidebarScroll from 'components/scroll/SidebarScroll';
import { QuickAlert } from "theme/components/alerts";
import SimpleDataTable from "views/global/SimpleDataTable";
import SimpleSidebar from "views/global/SimpleSidebar";
import FormGenerate from "views/global/FormGenerate";
import { userController } from 'lib/user';
import handleConfirmation from 'lib/handleConfirmation';
import handleSidebar from "lib/handleSidebar";


function UserManagement() {
    //IMPORT CONTROLLER
    const { setAlert, clearAlert, showAlert } = QuickAlert();
    const {saveRecord, getSingleRecord} = userController();
    const [status, setStatus] = useState({
        sidebarForm: false,
        loading: false,
        formLoading: false,
        saveNewList: false,
        editListEnabled: false
    });
    const [input, setInput] = useState({});
    const fieldNames = [
        {label: "Full Name", name:"name", type:"text", required: true},
        {label: "Email", name:"email", type:"email", required: true},
        {label: "Role", name:"role", type:"select", required: true, options: ["admin","office","operation","maintenance","cleaner"]},
        {label: "Password", name:"password", type:"password", required: true},
        {label: "Confirm Password", name:"password_confirmation", type:"password", required: true},
    ]
    const trigerSaveList = status.saveNewList ? false : true;


    //CHECKBOX MENU
    const checkboxMenu = [
        {
            icon: <MdDeleteOutline />,
            name: "Delete",
            color: "red",
            handle : (checked) => handleConfirmation(checked, "delete", "member", "Deleting selected team member will remove all associated data as well. This action cannot be undone.", "/user/management/delete",(response) => {
                clearAlert();
                setStatus(prevStatus => ({
                    ...prevStatus,
                    saveNewList: trigerSaveList,
                }));
                setAlert(response.data.status, response.data.message);
            },
            (error) => {
                setAlert("error",error.response.data.errors);
            }),
        }
    ];

    //CREATE LIST SHOW & HIDE SIDEBAR
    const createList = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            sidebarForm: true,
            editListEnabled: false
        }));
        setInput({});
        handleSidebar();
    }
    const hideList = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            sidebarForm: false,
            editListEnabled: false
        }));
        setInput({});
        clearAlert();
        handleSidebar(false);
    }

    //SAVE LIST DETAIL
    const saveListHandle = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            loading: true,
        }));
        saveRecord('/user/management/create',input).then(response => {
            return response;
        }).then(response => {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
                saveNewList: trigerSaveList,
            }));
            hideList();
        }).catch(function(error) {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
            }));
            setAlert("error",error.response.data.errors);
            SidebarScroll();
        });
    }
    //EdIT LIST SAVE
    const editListHandle = (row) => {
        handleSidebar();
        setStatus(prevStatus => ({
            ...prevStatus,
            sidebarForm: true,
            editListEnabled: true,
            formLoading: true
        }));
        getSingleRecord("/user/management/get/"+row.id).then(response => {
            return response;
        }).then(response => {
            setInput((prev) => ({
                ...prev,
                ...response.data.item,
            }));
            setStatus(prevStatus => ({
                ...prevStatus,
                formLoading: false
            }));
        }).catch(function(error) {
            setAlert("error",error.response.data.errors);
            setStatus(prevStatus => ({
                ...prevStatus,
                formLoading: false
            }));
        });
    }
    //HIDE SIDEBAR
    useEffect(() => {
        clearAlert();
    }
    ,[status.sidebarForm, status.editListEnabled]);

    const tableMenuItems = [
        {
            icon: <MdEdit />,
            title: "Edit Member",
            handle : editListHandle,
        },
        {
            icon: (row) => {
                return row.status === "active" ? <MdPersonOff /> : <MdPerson />;
            },
            title: (row) => {
                return row.status === "active" ? "Deactive Member" : "Activate Member";
            },
            handle: (row) => {
                const status = row.status === "active" ? "deactive" : "active";
                const message = row.status === "active" ? "Deactivating member will restrict member from accessing the system." : "Activating member will allow member to access the system.";
                const ID = {};
                ID[row.id] = true;
                handleConfirmation(ID, status, "member", message, "/user/management/status",(response) => {
                    clearAlert();
                    setStatus(prevStatus => ({
                        ...prevStatus,
                        saveNewList: trigerSaveList,
                    }));
                    setAlert(response.data.status, response.data.message);
                },
                (error) => {
                    setAlert("error",error.response.data.errors);
                });
            }
        },
    ];

    return (
        <Box  pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Flex justify='space-between' mb='20px' justifyContent="flex-end">
                <Button
                variant="outline"
                colorScheme="brand"
                fontWeight="light"
                leftIcon={<MdPerson />}
                _hover={{ bg: "brand.500", color: "white"}}
                transition='background-color 0.2s linear'
                onClick={createList}
                me="10px"
                >
                    Create New Member
                </Button>
            </Flex>
            <Card mb='20px' p='40px'>
                {status.sidebarForm===false ? showAlert() : null }
                <SimpleDataTable
                    title="Team Members"
                    src="/user/management/list"
                    search={true}
                    checkbox={true}
                    checkboxMenu={checkboxMenu}
                    tableMenu={tableMenuItems}
                    trigger={status.saveNewList}
                />
            </Card>
            <SimpleSidebar
            status={status.sidebarForm}
            title={status.editListEnabled ? "Edit Member" : "Create New Member"}
            save={{onClick:saveListHandle,isLoading:status.loading,text:status.editListEnabled ? "Update" : "Create Member"}}
            cancel={{onClick:hideList, text:"Cancel"}}
            loading={status.formLoading}
            >
                {status.sidebarForm===false ?  null : showAlert() }
                {status.sidebarForm===true &&
                    <FormGenerate fields={fieldNames} setFields={setInput} defaultValue={input} />
                }
            </SimpleSidebar>
        </Box>

    );
}
export default UserManagement;
