import { useLocalStorage } from 'lib/useLocalStorage';
import axios from 'axios';
import { useHistory} from "react-router-dom";

export const useAuth = () => {

  const [token, setToken] = useLocalStorage('token', null);
  const [user, setUser] = useLocalStorage('user', null);
  const history = useHistory();
  const getToken = JSON.parse(localStorage.getItem('token'));

  const isAuthenticated = () => {
    return token !== null;
  };

  const login = (data) => {
    const { email, password } = data;
    return axios.post(process.env.REACT_APP_API+'/login', {email, password}, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  };

  const guestRequest = (src,data) => {
    return axios.post(process.env.REACT_APP_API+src, { ...data }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  };

  const Authenticate = (token, user) => {
    setToken(token);
    setUser(user);
  };

  const logout = () => {
    axios.post(process.env.REACT_APP_API+'/user/logout', {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken}`
      }
    }).then(() => {
      localStorage.clear();
      setToken(null);
      setUser(null);
      localStorage.clear();
      history.push("/");
    });
  };

  return { isAuthenticated, login, guestRequest, Authenticate, logout };
}
