// Chakra imports
import {Box, Button, Flex, Input, useColorModeValue } from "@chakra-ui/react";
// Assets
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

function SimpleUpload(props) {
   //VARIABLES 
  const { content, onUpload, params, ...rest } = props;
  

  //STATES
  const [myFiles, setMyFiles] = useState([]);
  const [status, setStatus] = useState(false);

  //DROPZONE
  const {acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({ 
    ...params,
    onDrop: (upload) => {
      setStatus(true);
      setMyFiles(upload);
    },
  });

  //ON DROP
  useEffect(() => {
    onUpload(acceptedFiles, fileRejections, status);
  }, [myFiles]);



  return (
    <Box
      w="100%"
    >
      <Flex
        align='center'
        justify='center'
        bg="gray.100"
        border='1px dashed'
        borderColor="secondaryGray.100"
        borderRadius='16px'
        h='max-content'
        minH='100%'
        cursor='pointer'
        {...getRootProps({ className: "dropzone" })}
        {...rest}>
        <Input variant='main' {...getInputProps()} />
        <Button variant='no-effects'>{content}</Button>
      </Flex>
    </Box>
  );
}

export default SimpleUpload;
