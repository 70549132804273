import { React, useState, useEffect} from 'react';
import {
    Box,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure,
    ModalCloseButton,
    Flex} from '@chakra-ui/react';

import SimpleSidebar from "views/global/SimpleSidebar";
import BoxDataTable from "views/global/BoxDataTable";
import SidebarScroll from 'components/scroll/SidebarScroll';
import { QuickAlert } from "theme/components/alerts";
import { MdEdit, MdDeleteOutline,MdList,MdPerson, MdHouseSiding} from "react-icons/md";
import handleSidebar from "lib/handleSidebar";
import { userController } from 'lib/user';
import FormGenerate from "views/global/FormGenerate";
import handleConfirmation from 'lib/handleConfirmation';
import DOMPurify from 'dompurify';

function NoteTab(props) {

    const { id, title, Datatype} = props;
    const dataTitle = Datatype.charAt(0).toUpperCase() + Datatype.slice(1);

    const { setAlert, clearAlert, showAlert } = QuickAlert();
    const {saveRecord, userDetail} = userController();
    const { getUser } = userDetail();
    const [input, setInput] = useState(id ? {property_id: id} : null);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [status, setStatus] = useState({
        sidebarForm: false,
        loading: false,
        formLoading: false,
        saveNewList: false,
        editListEnabled: false,
        viewListEnabled: false,
        noteTitle: "",
        selectedImage: null,
    });
    const trigerSaveList = status.saveNewList ? false : true;
    const fieldNames = [
        {label: "Title", name:"title", type:"text", required: true},
        {label: "Attachment", name:"attachment", type:"file", required: false, uploadURL:"/user/attachment/upload", deleteURL:"/user/attachment/delete/",uploadKey:"note_id", uploadID:id+getUser.id},
        {label:"Description", name:"description", type:"editor", required: false},
    ]

    const checkboxMenu = [
        {
            icon: <MdDeleteOutline />,
            name: "Delete",
            color: "red",
            handle : (checked) => handleConfirmation(checked, "delete", Datatype, "Are you sure you want to delete selected "+Datatype+" This action cannot be undone.", "/user/note/delete",(response) => {
                clearAlert();
                setStatus(prevStatus => ({
                    ...prevStatus,
                    saveNewList: trigerSaveList,
                }));
                setAlert(response.data.status, response.data.message.replace("Item", dataTitle));
            },
            (error) => {
                setAlert("error",error.response.data.errors);
            }),
        }
    ];

    const tableMenuItems = [
        {
            icon: <MdEdit />,
            title: "Edit "+ dataTitle,
            handle : (row) => {
                setStatus(prevStatus => ({
                    ...prevStatus,
                    sidebarForm: true,
                    editListEnabled: true,
                    formLoading: false,
                    viewListEnabled: false,
                }));
                setInput((prev) => ({
                    ...prev,
                    ...row,
                }));
                handleSidebar();
            },
        },
    ];

    const buttons = [
        {
            title: "Add "+dataTitle,
            variant: "outline",
            color: "brand",
            icon: <MdList />,
            handle: () => {
                setStatus(prevStatus => ({
                    ...prevStatus,
                    sidebarForm: true,
                    editListEnabled: false,
                    viewListEnabled: false,
                }));
                setInput(id ? {property_id: id, type:Datatype} : {type:Datatype});
                handleSidebar();


            }
        },
    ];

    const hideList = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            sidebarForm: false,
            editListEnabled: false,
            viewListEnabled: false,
            noteTitle: "",
        }));
        setInput(id ? {property_id: id, type:Datatype} : {type:Datatype});
        handleSidebar(false);
        clearAlert();
    }

    const saveListHandle = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            loading: true,
        }));
        saveRecord('/user/note/create',input).then(response => {
            return response;
        }).then(response => {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
                saveNewList: trigerSaveList,
            }));
            hideList();
        }).catch(function(error) {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
            }));
            setAlert("error",error.response.data.errors);
            SidebarScroll();
        });
    }

    useEffect(() => {
        setStatus(prevStatus => ({
            ...prevStatus,
            saveNewList: trigerSaveList,
        }));
    } ,[Datatype]);


    return (
        <>
        <Box>
            {status.sidebarForm===false ? showAlert() : null }
            <BoxDataTable
                title={title}
                buttons={id ? buttons : false}
                src="/user/note/list"
                defaultCalander="due_date"
                additionalOptions={id ? {property_id: id, type:Datatype} : {type:Datatype}}
                search={true}
                checkboxMenu={id ? checkboxMenu : false}
                checkbox={id ? true : false}
                tableMenu={id ? tableMenuItems : false}
                trigger={status.saveNewList}
                design="dragable"
                dragableType="Note"
                SourceType={Datatype}
                tableContent={(item)=>{
                    return (
                        <Box
                        key={item.id}
                        cursor={"pointer"}
                        onClick={() => {
                            setStatus(prevStatus => ({
                                ...prevStatus,
                                sidebarForm: true,
                                viewListEnabled: true,
                                noteTitle: item.title,
                                formLoading: false,
                            }));
                            setInput((prev) => ({
                                ...prev,
                                ...item,
                            }));
                            handleSidebar();
                        }}
                        >

                            <Text className={"reservation-item-title"}>{item.title}</Text>
                            <Text className="muted-text note-description" mb={2}>
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((item.description.length > 300 ? item.description.substring(0,300)+"..." : item.description),{ ALLOWED_TAGS: [] })}} />
                                </Text>
                            <Flex
                            display={{base:"block", md:"flex"}}
                            >
                                <Text className="muted-text d-flex d-icon-5 d-icon-fx-18" me={4}><MdPerson/> {item.user.name}</Text>
                                {!id && item.property && <Text className="muted-text d-flex d-icon-5 d-icon-fx-18"><MdHouseSiding/> {item.property.identifier}</Text>}
                            </Flex>
                        </Box>
                    )
                }}
            />
            <SimpleSidebar
            status={status.sidebarForm}
            title={status.viewListEnabled ? status.noteTitle : (status.editListEnabled ? "Edit "+dataTitle : "Add "+dataTitle)}
            save={{onClick:saveListHandle,isLoading:status.loading,text:status.editListEnabled ? "Update "+dataTitle : "Add "+dataTitle}}
            cancel={{onClick:hideList, text:"Cancel"}}
            loading={status.formLoading}
            viewOnly={status.viewListEnabled}
            >
                {status.sidebarForm===false ?  null : showAlert() }
                {status.sidebarForm===true && !status.viewListEnabled &&
                    <FormGenerate fields={fieldNames} setFields={setInput} defaultValue={input} />
                }
                {status.sidebarForm===true && status.viewListEnabled &&
                    <>
                    <Box
                    mb={2}
                    className='task-header-meta'
                    display={{base:"block", md:"flex"}}
                    justifyContent={{base:"center", md:"space-between"}}
                    alignItems={{base:"center", md:"center"}}
                    >
                        {input.user.name &&
                        <Text className='task-header-created-date task-header-item'>
                            <MdPerson/>
                            {input.user.name}
                        </Text>
                        }
                    </Box>
                    <Text
                    mb={4}
                    >
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(input.description) }} />
                    </Text>
                    <Box
                    display={input.attachments && input.attachments.length > 0 ? "block" : "none"}
                    mb={4}
                    >
                        {input.attachments && input.attachments.length > 0 && input.attachments.map((attachment, index) => (
                            <Box
                            key={index}
                            className='attachment-preview'
                            >
                                <Box
                                display="flex"
                                justifyContent='center'
                                alignItems='center'
                                flexDirection='column'
                                width='100%'
                                height="100%"
                                onClick={()=>{
                                    setStatus(prevStatus => ({
                                        ...prevStatus,
                                        selectedImage: attachment.path,
                                    }));
                                    onOpen();
                                }}
                                cursor={"pointer"}
                                >
                                <img src={process.env.REACT_APP_URL+"/"+attachment.path} alt={attachment.name} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Modal
                    isCentered
                    onClose={onClose}
                    isOpen={isOpen}
                    motionPreset="slideInBottom"
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalCloseButton />
                            <ModalBody>
                                {status.selectedImage &&
                                <img src={process.env.REACT_APP_URL+"/"+status.selectedImage} alt="attachment" />
                                }
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                    </>
                }
            </SimpleSidebar>
        </Box>
        </>
    );
}
export default NoteTab;
