import { React, useState} from 'react';
import {
    Box,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    useDisclosure,
    ModalCloseButton,
    Flex,
    Textarea,} from '@chakra-ui/react';

import SimpleSidebar from "views/global/SimpleSidebar";
import BoxDataTable from "views/global/BoxDataTable";
import SidebarScroll from 'components/scroll/SidebarScroll';
import { QuickAlert } from "theme/components/alerts";
import { MdDeleteOutline, MdEdit, MdList, MdBookmarkBorder, MdFilterNone, MdOutlineWatchLater, MdOutlineEvent, MdCheckCircle, MdPauseCircle, MdPerson, MdHouseSiding} from "react-icons/md";
import handleSidebar from "lib/handleSidebar";
import { userController } from 'lib/user';
import FormGenerate from "views/global/FormGenerate";
import handleConfirmation from 'lib/handleConfirmation';
import DOMPurify from 'dompurify';


function TaskTab(props) {

    const { id, title } = props;

    const { setAlert, clearAlert, showAlert } = QuickAlert();
    const {saveRecord, getSingleRecord, userDetail, deleteRecord} = userController();
    //get the current id from the url
    const { getUser } = userDetail();
    const advanceGroup = ["admin","office"].includes(getUser.role);

    const [input, setInput] = useState(id ? {property_id: id} : null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [status, setStatus] = useState({
        sidebarForm: false,
        loading: false,
        formLoading: false,
        saveNewList: false,
        editListEnabled: false,
        viewListEnabled: false,
        taskTitle: "",
        selectedImage: null,
        newComment: null,
    });
    const trigerSaveList = status.saveNewList ? false : true;
    const fieldNames = [
        {label: "Title", name:"title", type:"text", required: true,},
        {label: "Attachment", name:"attachment", type:"file", required: false, uploadURL:"/user/attachment/upload", deleteURL:"/user/attachment/delete/",uploadKey:"task_id", uploadID:id+getUser.id},
        {label:"Description", name:"description", type:"editor", required: false},
        {label: "Department", name:"department", type:"select", required: false, options: ["office","operation","maintenance","cleaner"]},
        {label:"Select Team", name:"assignee_id", type:"dynamic-select", required: false, src:"/user/dynamic/list/users", placeholder:"member name"},
        {label:"Category", name:"categories_id", type:"dynamic-select", required: false, src:"/user/dynamic/list/categories", placeholder:"category name", multiple:false},
        {label: false, name:"categories_id_new", type:"text", required: false, placeholder:"Or create new category"},
        {label:"Tags", name:"tags", type:"dynamic-select", required: false, src:"/user/dynamic/list/tags", placeholder:"category name", multiple:true},
        {label: false, name:"tags_new", type:"text", required: false, placeholder:"add new tags seprated by comma"},
        {label: "Priority", name:"priority", type:"select", required: false, options: ['low', 'medium', 'high', 'very urgent'], permissions: ["operation", "maintenance"]},
        {label: "Due Date", name:"due_date", type:"datetime-local", required: false, permissions: ["operation", "maintenance"]},
        {label: "Status", name:"status", type:"select", required: false, options: ['pending','completed'], permissions: ["operation", "cleaner", "maintenance"]},
    ]


    const checkboxMenu = [
        {
            icon: <MdDeleteOutline />,
            name: "Delete",
            color: "red",
            handle : (checked) => handleConfirmation(checked, "delete", "task", "Are you sure you want to delete selected task. This action cannot be undone.", "/user/task/delete",(response) => {
                clearAlert();
                setStatus(prevStatus => ({
                    ...prevStatus,
                    saveNewList: trigerSaveList,
                }));
                setAlert(response.data.status, response.data.message);
            },
            (error) => {
                setAlert("error",error.response.data.errors);
            }),
        }
    ];
    const tableMenuItems = [
        {
            icon: <MdEdit />,
            title: "Edit Task",
            handle : (row) => {
                setStatus(prevStatus => ({
                    ...prevStatus,
                    sidebarForm: true,
                    editListEnabled: true,
                    viewListEnabled: false,
                    formLoading: true
                }));
                handleSidebar();
                getSingleRecord("/user/task/get/"+row.id).then(response => {
                    return response;
                }).then(response => {
                    setInput((prev) => ({
                        ...prev,
                        ...response.data.item,
                    }));
                    setStatus(prevStatus => ({
                        ...prevStatus,
                        formLoading: false
                    }));
                }).catch(function(error) {
                    setAlert("error",error.response.data.errors);
                    setStatus(prevStatus => ({
                        ...prevStatus,
                        formLoading: false
                    }));
                });
            },
        },
    ];

    const buttons = [
        {
            title: "Create Task",
            variant: "outline",
            color: "brand",
            icon: <MdList />,
            handle: () => {
                setStatus(prevStatus => ({
                    ...prevStatus,
                    sidebarForm: true,
                    editListEnabled: false,
                    viewListEnabled: false,
                }));
                setInput(id ? {property_id: id} : null);
                handleSidebar();


            }
        },
    ];

    const hideList = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            sidebarForm: false,
            editListEnabled: false,
            viewListEnabled: false,
            taskTitle: "",
        }));
        setInput(id ? {property_id: id} : null);
        handleSidebar(false);
        clearAlert();
    }

    const saveListHandle = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            loading: true,
        }));
        saveRecord('/user/task/create',input).then(response => {
            return response;
        }).then(response => {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
                saveNewList: trigerSaveList,
            }));
            hideList();
        }).catch(function(error) {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
            }));
            setAlert("error",error.response.data.errors);
            SidebarScroll();
        });
    }

    return (
        <>
        <Box>
            {status.sidebarForm===false ? showAlert() : null }
            <BoxDataTable
                title={title}
                buttons={id && advanceGroup ? buttons : false}
                src="/user/task/list"
                additionalOptions={id ? {property_id: id} : null}
                search={true}
                checkbox={id  && advanceGroup ? true : false}
                checkboxMenu={checkboxMenu}
                tableMenu={id ? tableMenuItems : false}
                trigger={status.saveNewList}
                design="dragable"
                dragableType="Task"
                defaultCalander="assigned_at"
                tableContent={(item)=>{
                    return (
                        <Box
                        cursor={"pointer"}
                        onClick={() => {
                            setStatus(prevStatus => ({
                                ...prevStatus,
                                sidebarForm: true,
                                viewListEnabled: true,
                                taskTitle: item.title,
                                formLoading: true
                            }));
                            handleSidebar();
                            getSingleRecord("/user/task/get/"+item.id).then(response => {
                                return response;
                            }).then(response => {
                                setInput((prev) => ({
                                    ...prev,
                                    ...response.data.item,
                                }));
                                setStatus(prevStatus => ({
                                    ...prevStatus,
                                    formLoading: false
                                }));
                            }).catch(function(error) {
                                setAlert("error",error.response.data.errors);
                                setStatus(prevStatus => ({
                                    ...prevStatus,
                                    formLoading: false
                                }));
                            });
                        }}
                        >
                            <Text className={"task-item-title "+item.priority}
                            mb={1}
                            >
                                {item.title}
                            </Text>
                            <Flex
                            display={{base:"block", md:"flex"}}
                            >
                                <Text className="muted-text d-flex d-icon-5" me={4}> <MdOutlineEvent/> {item.due_date}</Text>
                                <Text className="muted-text d-flex d-icon-5 d-icon-fx-18" me={4}><MdPerson/> {item.assignee ? item.assignee.name : item.department}</Text>
                                {!id && item.property && <Text className="muted-text d-flex d-icon-5 d-icon-fx-18"><MdHouseSiding/> {item.property.identifier}</Text>}
                            </Flex>
                        </Box>
                    )
                }}
            />
            <SimpleSidebar
            status={status.sidebarForm}
            title={status.viewListEnabled ? status.taskTitle : (status.editListEnabled ? "Edit Task" : "Create New Task")}
            save={{onClick:saveListHandle,isLoading:status.loading,text:status.editListEnabled ? "Update Task" : "Create Task"}}
            cancel={{onClick:hideList, text:"Cancel"}}
            loading={status.formLoading}
            viewOnly={status.viewListEnabled}
            >
                {status.sidebarForm===false ?  null : showAlert() }
                {status.sidebarForm===true && !status.viewListEnabled &&
                    <FormGenerate fields={fieldNames} setFields={setInput} defaultValue={input}/>
                }
                {status.sidebarForm===true && status.viewListEnabled && input &&
                    <>
                    <Box
                    mb={2}
                    className='task-header-meta'
                    display={{base:"block", md:"flex"}}
                    justifyContent={{base:"center", md:"space-between"}}
                    alignItems={{base:"center", md:"center"}}
                    >
                        {input.assigned_at &&
                        <Text className='task-header-created-date task-header-item'>
                            <MdOutlineWatchLater/>
                            {input.assigned_at}
                        </Text>
                        }
                        {input.due_date &&
                        <Text className="task-header-due-date task-header-item">
                            <MdOutlineEvent/>
                            {input.due_date}
                        </Text>
                        }
                        {input.status &&
                        <Text className={input.status+ ' task-header-status task-header-item'}>
                            {input.status==="completed" ? <MdCheckCircle/> : <MdPauseCircle/>}
                            {input.status==="completed" ? input.completed_at : input.status}
                        </Text>
                        }
                    </Box>
                    <Box
                    mb={4}
                    className='task-header-meta'
                    display={{base:"block", md:"flex"}}
                    alignItems={{base:"center", md:"center"}}
                    >
                        <Text className="task-header-assignee  task-header-item" me={5}>
                            Reporter: <span>{input.user ? input.user.name : "Admin"}</span>
                        </Text>
                        <Text className="task-header-assignee  task-header-item">
                            Assignee: <span> {input.assignee ? input.assignee.name : input.department}</span>
                        </Text>
                    </Box>
                    <Text
                    mb={4}
                    >
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(input.description) }} />
                    </Text>
                    <Box
                    className='task-footer-meta'
                    >
                        {input.categories &&
                        <Text
                        className='task-category-item'
                        >
                            <MdFilterNone/>
                            {input.categories.name}
                        </Text>
                        }
                        {input.tags && input.tags.map((tag, index) => (
                            <Text key={index} className='task-tags-item'>
                                <MdBookmarkBorder/> {tag}
                            </Text>
                        ))}
                    </Box>
                    <Box
                    display={input.attachments && input.attachments.length > 0 ? "block" : "none"}
                    mb={4}
                    >
                        {input.attachments && input.attachments.length > 0 && input.attachments.map((attachment, index) => (
                            <Box
                            key={index}
                            className='attachment-preview'
                            >
                                <Box
                                display="flex"
                                justifyContent='center'
                                alignItems='center'
                                flexDirection='column'
                                width='100%'
                                height="100%"
                                onClick={()=>{
                                    setStatus(prevStatus => ({
                                        ...prevStatus,
                                        selectedImage: attachment.path,
                                    }));
                                    onOpen();
                                }}
                                cursor={"pointer"}
                                >
                                <img src={process.env.REACT_APP_URL+"/"+attachment.path} alt={attachment.name} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Modal
                    isCentered
                    onClose={onClose}
                    isOpen={isOpen}
                    motionPreset="slideInBottom"
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalCloseButton />
                            <ModalBody>
                                {status.selectedImage &&
                                <img src={process.env.REACT_APP_URL+"/"+status.selectedImage} alt="attachment" />
                                }
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                    <hr className='divider' />
                    <Box mb={8}>
                        <Textarea
                        placeholder='Type your comment here'
                        mb={2}
                        value={status.newComment ? status.newComment : ""}
                        onChange={(e) => setStatus(prevStatus => ({
                            ...prevStatus,
                            newComment: e.target.value
                        }))}
                        >
                        </Textarea>
                        <Box textAlign="right">
                            <Button
                            colorScheme="brand"
                            size="sm"
                            onClick={() => {
                                saveRecord('/user/comment/add', {comment: status.newComment, task_id: input.id, property_id: input.property_id}).then(response => {
                                    return response;
                                }).then(response => {
                                    setInput((prev) => ({
                                        ...prev,
                                        comments: [response.data.comment, ...prev.comments], // append new comment to the list
                                    }));
                                    setStatus(prevStatus => ({
                                        ...prevStatus,
                                        newComment: null,
                                    }));
                                }).catch(function(error) {
                                    setAlert("error",error.response.data.errors);
                                });
                            }}
                            >
                                Add Comment
                            </Button>
                        </Box>
                    </Box>
                    <Box
                    display={input.comments && input.comments.length > 0 ? "block" : "none"}
                    mb={4}
                    className='comments-container'
                    >
                        {input.comments && input.comments.length > 0 && input.comments.map((comment, index) => (
                        <Box
                        className='comments-item'
                        key={index}
                        mb={8}
                        >
                            <Box
                            display={{base:"block", md:"flex"}}
                            >
                                <Box>
                                    <Box
                                    className={'circle-string item-' + comment.user.name.charAt(0).toLowerCase()}
                                    >
                                    {comment.user.name.charAt(0) + comment.user.name.charAt(1)}
                                    </Box>
                                </Box>
                                <Box>
                                    <Text
                                    className='comment-header'
                                    >
                                    {comment.user.name} <span>{comment.created_at}</span>
                                    </Text>
                                    {comment.comment}
                                    {comment.user.id === getUser.id &&
                                        <span
                                        className='comment-footer'
                                        onClick={() => {
                                            deleteRecord('/user/comment/delete', {id: comment.id}).then(response => {
                                                setInput((prev) => ({
                                                    ...prev,
                                                    comments: prev.comments.filter(item => item.id !== comment.id), // remove the comment from the list
                                                }));
                                            }
                                            ).catch(function(error) {
                                                setAlert("error",error.response.data.errors);
                                            });
                                        }}
                                        >
                                        Delete
                                        </span>
                                    }
                                </Box>
                            </Box>
                        </Box>
                        ))}
                    </Box>
                    </>
                }
            </SimpleSidebar>
        </Box>
        </>

    );
}
export default TaskTab;
