import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column' className="main_logo">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="340.000000pt" height="90.000000pt" viewBox="0 0 340.000000 90.000000"
             preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
               fill="#000000" stroke="none">
                <path d="M48 745 c-3 -3 29 -142 72 -308 l77 -302 89 0 89 0 52 200 c28 110
52 196 53 190 0 -5 22 -95 48 -200 l48 -190 89 0 90 0 77 290 c42 160 77 298
77 308 1 16 -7 18 -76 15 l-78 -3 -42 -205 c-23 -113 -43 -212 -45 -220 -2 -8
-23 71 -47 175 -25 105 -48 205 -53 223 l-9 33 -81 -3 -81 -3 -49 -200 c-26
-110 -51 -207 -55 -215 -3 -8 -23 73 -44 180 -21 107 -41 205 -44 218 -5 20
-11 22 -79 22 -40 0 -75 -2 -78 -5z"/>
                <path d="M990 440 l0 -311 73 3 72 3 3 112 c2 101 5 113 21 113 13 0 36 -34
80 -115 l62 -115 80 0 c49 0 79 4 79 11 0 6 -30 61 -66 122 -36 61 -61 113
-55 115 24 8 76 49 95 75 17 23 21 43 21 102 0 59 -4 79 -21 102 -54 72 -123
93 -314 93 l-130 0 0 -310z m300 160 c12 -12 20 -33 20 -54 0 -51 -36 -76
-111 -76 l-59 0 0 75 0 75 65 0 c52 0 69 -4 85 -20z"/>
                <path d="M1945 737 c-65 -22 -99 -43 -138 -86 -46 -51 -62 -83 -76 -153 -40
-191 104 -368 299 -368 154 0 274 103 298 254 30 192 -94 351 -283 362 -33 2
-78 -2 -100 -9z m191 -85 c49 -25 67 -45 93 -102 23 -51 28 -141 10 -201 -7
-21 -25 -54 -42 -75 -81 -98 -250 -99 -331 -3 -43 50 -56 90 -56 166 0 113 53
198 145 232 42 15 134 7 181 -17z"/>
                <path d="M2610 604 c-19 -8 -47 -27 -62 -41 l-28 -27 0 37 c0 36 -1 37 -40 37
l-40 0 0 -305 0 -305 40 0 40 0 0 106 0 106 36 -32 c47 -41 73 -50 140 -50
163 0 267 171 208 343 -39 114 -184 179 -294 131z m158 -80 c15 -11 37 -40 50
-64 59 -116 -16 -260 -135 -260 -101 0 -163 68 -163 177 0 69 23 112 80 151
29 20 44 23 88 20 31 -3 64 -13 80 -24z"/>
                <path d="M3093 600 c-58 -26 -85 -71 -80 -130 7 -62 44 -92 154 -126 98 -29
123 -46 123 -81 0 -40 -33 -63 -94 -63 -57 0 -99 23 -111 60 -5 16 -15 20 -47
20 -39 0 -40 -1 -34 -28 30 -124 243 -169 334 -69 31 33 39 94 18 135 -19 37
-54 57 -154 88 -81 25 -112 46 -112 78 0 42 36 66 98 66 45 0 92 -31 92 -61 0
-16 7 -19 44 -19 l45 0 -9 31 c-15 51 -39 79 -83 100 -54 24 -130 24 -184 -1z"/>
            </g>
        </svg>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
