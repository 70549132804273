import React from "react";

// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
  Box,
  Button,
} from "@chakra-ui/react";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { Link } from "react-router-dom";


export default function TableMenu(props) {
  const {menuItems, buttonTitle, buttonIcon,buttonVariant, buttonColor, menuItemSize, menuItemMargin,row, ...rest } = props;

  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("gray.100", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  return (
    <Menu isOpen={isOpen1} onClose={onClose1}>
      {
      buttonTitle ? 
      <MenuButton 
        as={Button} 
        rightIcon={buttonIcon}
        variant={buttonVariant}
        colorScheme={buttonColor} 
        onClick={onOpen1}
        fontWeight="light"
        _hover={{ bg: buttonColor+".500", color: "white"}}
        _active={{ bg: buttonColor+".500", color: "white"}}
        >
        {buttonTitle}
      </MenuButton> 
      : <MenuButton
        align='center'
        justifyContent='center'
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w='37px'
        h='37px'
        lineHeight='100%'
        onClick={onOpen1}
        borderRadius='10px'
        {...rest}>
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w='24px' h='24px' />
      </MenuButton>
      }
      <MenuList
        w='200px'
        minW='unset'
        maxW='200px !important'
        border='transparent'
        backdropFilter='blur(63px)'
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius='20px'
        p='15px'>
        {menuItems.map((item, index) => (
          <MenuItem transition='0.2s linear' p='0px' _active={{bg: "transparent",}} _focus={{bg: "transparent",}} 
          mb={index === menuItems.length - 1 ? 0 : "15px"}
          //on click use item.handle function and pass parameters as "test"
          onClick={item.handle ? () => item.handle(row) : null}
          key={index}
          >
          <Flex align='center'>
            <Box me='10px' fontSize='lg'>
              {item.icon}
            </Box>
            {item.link ?
            <Link to={item.link}
            >
              <Text fontWeight='400' fontSize={menuItemSize ? menuItemSize : 'sm'} mb={menuItemMargin ? menuItemMargin : '5px'}>
                {item.title}
              </Text>
            </Link>
            :<Text fontWeight='400' fontSize={menuItemSize ? menuItemSize : 'sm'} mb={menuItemMargin ? menuItemMargin : '5px'}>
              {item.title}
            </Text>
            }
          </Flex>
        </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
