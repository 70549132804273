import { React, useState} from 'react';
import {
    Box,
    Text,
    Flex} from '@chakra-ui/react';

import SimpleSidebar from "views/global/SimpleSidebar";
import BoxDataTable from "views/global/BoxDataTable";
import SidebarScroll from 'components/scroll/SidebarScroll';
import { QuickAlert } from "theme/components/alerts";
import { MdEuro, MdEdit, MdLaptopChromebook, MdOutlineEvent, MdHouseSiding} from "react-icons/md";
import handleSidebar from "lib/handleSidebar";
import { userController } from 'lib/user';
import FormGenerate from "views/global/FormGenerate";


function CityTaxTab(props) {

    const { id, title } = props;

    const { setAlert, clearAlert, showAlert } = QuickAlert();
    const {saveRecord} = userController();
    const [input, setInput] = useState(id ? {property_id: id} : null);

    const [status, setStatus] = useState({
        sidebarForm: false,
        loading: false,
        formLoading: false,
        saveNewList: false,
        editListEnabled: false,
    });
    const trigerSaveList = status.saveNewList ? false : true;
    const fieldNames = [
        {label: "Amount", name:"amount", type:"text", required: false},
        {label: "Link", name:"link", type:"text", required: false},
        {label: "Currency", name:"currency", type:"select", required: false, options: ['EUR']},
        {label: "Due Date", name:"due_date", type:"date", required: true},
        {label: "Status", name:"status", type:"select", options: ['pending','paid']},
    ]

    const tableMenuItems = [
        {
            icon: <MdEdit />,
            title: "Edit City Tax",
            handle : (row) => {
                setStatus(prevStatus => ({
                    ...prevStatus,
                    sidebarForm: true,
                    editListEnabled: true,
                    formLoading: false
                }));
                setInput((prev) => ({
                    ...prev,
                    ...row,
                }));
                handleSidebar();
            },
        },
    ];

    const hideList = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            sidebarForm: false,
            editListEnabled: false,
        }));
        setInput(id ? {property_id: id} : null);
        handleSidebar(false);
        clearAlert();
    }

    const saveListHandle = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            loading: true,
        }));
        saveRecord('/user/city-tax/update',input).then(response => {
            return response;
        }).then(response => {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
                saveNewList: trigerSaveList,
            }));
            hideList();
        }).catch(function(error) {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
            }));
            setAlert("error",error.response.data.errors);
            SidebarScroll();
        });
    }

    return (
        <>
        <Box>
            {status.sidebarForm===false ? showAlert() : null }
            <BoxDataTable
                title={title}
                src="/user/city-tax/list"
                additionalOptions={id ? {property_id: id} : null}
                search={true}
                checkbox={false}
                tableMenu={id ? tableMenuItems : false}
                trigger={status.saveNewList}
                design="dragable"
                dragableType="CityTax"
                defaultCalander="due_date"
                tableContent={(item)=>{
                    return (
                        <Box
                        key={item.id}
                        >

                            <Text className={item.status+" reservation-item-title"}>{item.reservation.name}</Text>
                            {item.reservation.email &&
                                <Text mb={2}><small>{item.reservation.email}</small></Text>
                            }
                            <Flex
                            display={{base:"block", md:"flex"}}
                            >
                                {item.amount && <Text className="muted-text d-flex d-icon-5 d-icon-fx-18" me={4}><MdEuro/> {item.amount}</Text>}
                                {item.due_date && <Text className="muted-text d-flex d-icon-5" me={4}> <MdOutlineEvent/> {item.due_date}</Text>}
                                {item.link && <Text className="muted-text d-flex d-icon-5" me={4}><MdLaptopChromebook/> <a target='_blank' className='primary-link' href={item.link}>{(() => {
                            const cleanedLink = item.link.replace(/^https?:\/\/(www\.)?/, '');
                            return cleanedLink.length > 40 ? `${cleanedLink.substring(0, 40)}...` : cleanedLink;
                        })()}</a></Text>}
                                {!id && item.reservation.property && <Text className="muted-text d-flex d-icon-5 d-icon-fx-18"><MdHouseSiding/> {item.reservation.property.identifier}</Text>}
                            </Flex>
                        </Box>
                    )
                }}
            />
            <SimpleSidebar
            status={status.sidebarForm}
            title="Edit City Tax"
            save={{onClick:saveListHandle,isLoading:status.loading,text:"Update City Tax"}}
            cancel={{onClick:hideList, text:"Cancel"}}
            loading={status.formLoading}
            >
                {status.sidebarForm===false ?  null : showAlert() }
                {status.sidebarForm===true &&
                    <FormGenerate fields={fieldNames} setFields={setInput} defaultValue={input} />
                }
            </SimpleSidebar>
        </Box>
        </>
    );
}
export default CityTaxTab;
