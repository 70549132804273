// Chakra imports
import { Box, Flex, useColorModeValue, Text} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
// Custom components
import { NavLink } from "react-router-dom";


function LayoutLogin(props) {
  const { children, illustrationBackground } = props;
  const primaryColor    = useColorModeValue("brand.600", "white");
  const whiteColor      = useColorModeValue("white", "white");
  const whiteColorAlpha = useColorModeValue("whiteAlpha.500", "white");

  return (
    <Flex position='relative' h='max-content'>
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w='100%'
        maxW={{ md: "66%", lg: "1313px" }}
        mx='auto'
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent='start'
        direction='column'>
        <NavLink
          to='/user'
          style={() => ({
            width: "fit-content",
            marginTop: "40px",
          })}>
          <Flex
            align='center'
            ps={{ base: "25px", lg: "0px" }}
            pt={{ lg: "0px", xl: "0px" }}
            w='fit-content'>
          </Flex>
        </NavLink>
        {children}
        <Box
          display={{ base: "none", md: "block" }}
          h='100%'
          minH='100vh'
          w={{ lg: "50vw", "2xl": "44vw" }}
          position='absolute'
          right='0px'>
          <Flex
            bg={`url(${illustrationBackground})`}
            justify='center'
            align='center'
            bgSize='cover'
            bgPosition='50%'
            w='100%'
            h='100%'
            position='absolute'
            borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
            >
              <Flex 
              display="block"
              borderColor={whiteColorAlpha}
              borderWidth="1px"
              borderStyle="solid"
              maxW="420px"
              w="80%"
              py={4}
              borderRadius={16}
              >
                <Text
                color={whiteColor}
                fontSize='18px'
                textAlign="center"
                fontWeight="300"
                >
                  {process.env.REACT_APP_EMAIL}
                </Text>
                <Text
                color={whiteColor}
                fontSize='32px'
                fontWeight='bold'
                textAlign='center'
                >
                  {process.env.REACT_APP_NAME}
                </Text>
              </Flex>
            </Flex>
        </Box>
        <Footer />
      </Flex>
    </Flex>
  );
}
// PROPS

LayoutLogin.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default LayoutLogin;
