import React from 'react';
import { Box, Flex, FormLabel, FormControl,FormHelperText } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/color-mode';


export default function Formfield({children, ...props }) {
    const required = props.required ? true : false;
    let muteColor = useColorModeValue("gray.400", "white");
    let display = props.display ? props.display : 'flex';
    let displaymargin = display==="block" ? "10px" : "0";
    
    return (
        <FormControl id={props.label ? props.label.toLowerCase().replace(/ /g, "_") : null} isRequired={required} mb="20px">
            <Flex display={{ base: "block", md: display }}>
                
                {props.label &&
                <Box flex="4" display='flex' alignItems='center' mb={{ 'sm': "10px", 'md': displaymargin }}>
                    <FormLabel mb={0}>{props.label}</FormLabel>
                </Box> }

                <Box flex="12">
                    {children}
                    {props.help ? <FormHelperText color={muteColor} fontSize='xs'>{props.help}</FormHelperText> : null}
                </Box>
            </Flex>
        </FormControl>
    );
}

