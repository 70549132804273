import axios from 'axios';

export const userController = () => {

  const getToken = JSON.parse(localStorage.getItem('token'));

  /*
  |--------------------------------------------------------------------------
  | GET USER DETAILS
  |--------------------------------------------------------------------------
  */
  const userDetail = () => {
    const getUser = JSON.parse(localStorage.getItem('user'));
    const userDetail = { getUser, getToken };
    return userDetail;
  }

  /*
  |--------------------------------------------------------------------------
  | UPDATE USER DETAILS
  |--------------------------------------------------------------------------
  */
  const updateDetail = (data) => {
    const { name, password,password_confirmation } = data;
    return axios.post(process.env.REACT_APP_API+'/user/setting/update', {name, password, password_confirmation}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken}`
      }
    })
  };

  /*
  |--------------------------------------------------------------------------
  | GET SINGLE RECORD
  |--------------------------------------------------------------------------
  */
  const getSingleRecord = (src) => {
    return axios.get(process.env.REACT_APP_API+src, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken}`
      }
    })
  };

  /*
  |--------------------------------------------------------------------------
  | SAVE CONTACT LIST
  |--------------------------------------------------------------------------
  */
  const saveRecord= (src, data) => {
    return axios.post(process.env.REACT_APP_API+src, {...data}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken}`
      }
    })
  };

  /*
  |--------------------------------------------------------------------------
  | DELETE LISTING
  |--------------------------------------------------------------------------
  */
  const deleteRecord = async (src, data) => {
    try {
      const token = JSON.parse(localStorage.getItem('token'));
      const response = await axios.post(process.env.REACT_APP_API + src, { ...data }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  /*
  |--------------------------------------------------------------------------
  | DYNAMIC RECORD PULLING
  |--------------------------------------------------------------------------
  */
  const dynamicRecord = async (src, keyword) => {
    if (keyword.length <= 3) {
      return [];
    }
    try {
      const response = await axios.get(process.env.REACT_APP_API + src + "?keyword="+keyword,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }

  /*
  |--------------------------------------------------------------------------
  | ATTACHMENT UPLOAD
  |--------------------------------------------------------------------------
  */
  const attachmentUpload= async (src, data) => {
    try {
      return await axios.post(process.env.REACT_APP_API+src, data,{
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${getToken}`
        }
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      return [];
    }
  };

  return {userDetail, updateDetail, saveRecord, attachmentUpload, getSingleRecord, dynamicRecord, deleteRecord};
}
