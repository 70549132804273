import Swal from 'sweetalert2';
import { userController } from 'lib/user';

const { deleteRecord } = userController();

const handleConfirmation = (checked, type, title, text, url, onSuccess, onError) => {
    const selected = Object.keys(checked).filter((key) => checked[key] === true);
    const pluralItem = (title.endsWith('y') ? title.slice(0, -1) + 'ies' : title + "s");
    text = selected.length <= 1 ? text : text.replace(title, pluralItem);
    title = selected.length <= 1 ? title : pluralItem;

    Swal.fire({
        title: 'Are you sure you want to ' + type + ' the selected ' + title + '?',
        text: text || "This action cannot be undone.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: "Yes " + type + (selected.length > 1 ? " " + selected.length : "") + " " + title,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await deleteRecord(url, { id: (selected.length <= 1 ? selected[0] : selected), type: type });
                    if (onSuccess) onSuccess(response);
                } catch (error) {
                    if (onError) onError(error);
                }
            }
    });
};

export default handleConfirmation;