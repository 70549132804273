import React, { useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Flex,
  CloseButton
} from "@chakra-ui/react";
export const QuickAlert = () => {
    
    var [AlertType, setAlertType] = useState("");
    var [AlertMessage, setAlertMessage] = useState("");

    var setAlert = (alertType, alertMessage) => {
        setAlertType(alertType);
        setAlertMessage(alertMessage);
        return;
    }

    var clearAlert = () => {
        setAlertType("");
        setAlertMessage("");
        return;
    }

    var showAlert = () => {
        return (
            AlertType !== "" && AlertMessage !== "" ?
                typeof AlertMessage === "object" ?
                    Object.keys(AlertMessage).map((key, index) => {
                        return (
                            Array.isArray(AlertMessage[key]) ? 
                                AlertMessage[key].map((message, index) => {
                                    return (
                                        <Alert 
                                        status={AlertType} 
                                        variant="subtle"
                                        mb={2}
                                        borderRadius={8}
                                        >
                                        <AlertIcon me={2} />
                                        <AlertDescription 
                                        fontSize={"sm"}
                                        >
                                            {message}
                                        </AlertDescription>
                                        </Alert>
                                    );
                                }) 
                            :
                            <Alert 
                            status={AlertType} 
                            variant="subtle"
                            mb={index === Object.keys(AlertMessage).length - 1 ? 5 : 2}
                            borderRadius={8}
                            >
                            <AlertIcon me={2} />
                            <AlertDescription 
                            fontSize={"sm"}
                            >
                                {AlertMessage[key]}
                            </AlertDescription>
                            </Alert>
                        );
                    })

                : <Alert 
                status={AlertType} 
                variant="subtle"
                mb={5}
                borderRadius={8}
                >
                <AlertIcon me={2} />
                <AlertDescription 
                fontSize={"sm"}
                w="100%"
                >
                    <Flex
                        w="100%"
                        justifyContent="space-between"
                    >
                        {AlertMessage}
                        <CloseButton size="sm" onClick={ () => clearAlert() } />
                    </Flex>
                </AlertDescription>
                </Alert> 
            : ""
        );
    }
    return {setAlert, clearAlert, showAlert};
};