//creat test page

import { React, useState } from 'react';
import { Box, Input, Button } from '@chakra-ui/react';
import Card from "components/card/Card.js";

import { QuickAlert } from "theme/components/alerts";
import { userController } from 'lib/user';
import Formfield from 'views/global/field';
import { useLocalStorage } from 'lib/useLocalStorage';


function UserSettings() {
    const { setAlert, clearAlert, showAlert } = QuickAlert();
    const { userDetail, updateDetail } = userController();
	const { getUser } = userDetail();

    const [user, setUser] = useLocalStorage('user', getUser);
    const [loading, setLoading] = useState(false);

    const [input, setInput] = useState({
        name: getUser.name,
        password: "",
        password_confirmation: "",
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleUpdateProfile = () => {
        setLoading(true);
        updateDetail(input).then(response => {
            clearAlert();
            setAlert(response.data.type,response.data.message);
            setUser(response.data.user);
            setLoading(false);
          }).catch(function(error) {
              setAlert("error",error.response.data.errors);
              setLoading(false);
          });
    }

    return (
        <Box  pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card mb='20px' p='40px'>
                {showAlert()}
                <Formfield label="Name" required="true">
                    <Input type="text" defaultValue={getUser.name} name="name" onChange={handleInput} />
                </Formfield>
                <Formfield label="Email">
                    <Input type="text" value={getUser.email}  disabled />
                </Formfield>
                <Formfield label="Password" help="Leave blank to keep the same password.">
                    <Input type="password" name="password" onChange={handleInput} />
                </Formfield>
                <Formfield label="Confirm Password">
                    <Input type="password" name="password_confirmation" onChange={handleInput} />
                </Formfield>
                <Formfield label="&nbsp;">
                    <Button isLoading={loading} variant="brand" minWidth="150px" onClick={handleUpdateProfile}>Update</Button>
                </Formfield>
            </Card>
        </Box>
    );
}
export default UserSettings;
