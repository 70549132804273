import React from "react";
import { Link } from "react-router-dom";
import {Box, Text, Flex, Button} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/color-mode";
import BeatLoader from "react-spinners/BeatLoader";
import { MdOutlineClose} from "react-icons/md";

export default function SimpleSidebar({children, ...option }) {

    const animation = option.status ? "all 0.5s ease" : "all 1s linear";
    const muteColor = useColorModeValue("gray.400", "white");

    return (
        <Box>
            <Box
            className="simple-sidebar"
            right={option.status ? "0" : "-100%"}
            bg="white"
            w="100%"
            h="100%"
            position="fixed"
            top="0"
            minW="300px"
            maxW="700px"
            zIndex="99"
            p={{ sm: "40px", md: "80px"}}
            overflowY="auto"
            scrollBehavior={"smooth"}
            transition={animation}
            >
                <Box id="simple-sidebar-notification"></Box>
                <Text
                className="circle-button circle-button-gray position-sidebar"
                onClick={option.cancel.onClick}
                >
                    <MdOutlineClose/>
                </Text>
                
                { option.title && (
                    <Text
                    fontSize="22px"
                    fontWeight="700"
                    mb="10px"
                    >
                    {option.title}
                    </Text>
                )}
                {option.description && (
                    <Text
                    color={muteColor}
                    mb="20px"
                    >
                    {option.description}
                    </Text>
                )}
                {option.loading ?
                <Flex
                justify="center"
                w="100%"
                my={20}
                >
                    <BeatLoader
                        loading="true"
                        size={15}
                        aria-label="Loading Spinner"
                        color="#422CFA"
                        />
                </Flex>
                : children
                }

                <Flex justify="flex-end">
                    <Button
                        variant="outline"
                        colorScheme="blackAlpha"
                        fontWeight="light"
                        _hover={{ bg: "blackAlpha.500", color: "white"}}
                        transition='background-color 0.2s linear'
                        me="10px"
                        {...option.cancel}
                    >
                        {option.viewOnly ? "Close" : option.cancel.text}
                    </Button>
                    {option.viewOnly!==true && (
                    <Button
                        colorScheme="brand"
                        fontWeight="light"
                        {...option.save}
                    >
                        {option.save.text}
                    </Button>
                    )}
                </Flex>
            </Box>
            <Box
            hidden={!option.status}
            bg="black"
            w="100%"
            h="100%"
            position="fixed"
            right="0"
            top="0"
            zIndex="8"
            opacity="0.5"
            />
        </Box>

    )
}
