import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Link,
} from "@chakra-ui/react";
import LayoutLogin from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from "lib/auth";
import { QuickAlert } from "theme/components/alerts";

function SignIn() {
  const { setAlert, clearAlert, showAlert } = QuickAlert();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resetParam = searchParams.get("reset");
  const tokenParam = searchParams.get("token");
  const emailParam = searchParams.get("email");

  // Correct state initialization
  const [status, setStatus] = useState({
    showPassword: false,
    showLogin: resetParam ? false : true,
    showPassworForget: false,
    showPasswordReset: resetParam ? true : false,
  });
  const [input, setInput] = useState({
    email : emailParam ? emailParam : "",
    token : tokenParam ? tokenParam : "",
  });

  // Function to toggle password visibility
  const handleClick = () => {
    setStatus((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const history = useHistory();
  const { login, guestRequest, Authenticate } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    login(input)
      .then((response) => {
        return response;
      })
      .then((response) => {
        clearAlert();
        Authenticate(response.data.token, response.data.user);
        setLoading(false);
        history.push("/user/dashboard");
      })
      .catch(function (error) {
        setLoading(false);
        setAlert("error", error.response.data.errors);
      });
  };

  const handlePassword = (src) => {
    setLoading(true);
    guestRequest(src, input).then((response) => {
      return response;
    }).then((response) => {
      clearAlert();
      setAlert(response.data.status, response.data.message);
      setLoading(false);
      setStatus((prevState) => ({
        ...prevState,
        showPassworForget: false,
        showLogin: true,
        showPasswordReset: false,
      }));
      history.push("/auth/login");
    })
    .catch(function (error) {
      setLoading(false);
      setAlert("error", (error.response.status === 401 ? "The link has been expired." : error.response.data.errors));
    });
  };


  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const headingText = status.showLogin ? "Sign In" : "Reset Password";

  return (
    <LayoutLogin illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading fontSize="36px" mb="25px">
            {headingText}
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          {showAlert()}
          {status.showPassworForget && (
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="8px"
              >
                Email<Text color="red.500">*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                mb="24px"
                fontWeight="500"
                size="lg"
                name="email"
                onChange={handleInput}
              />
              <Flex justifyContent="space-between" align="center" mb="24px">
                <Text
                  color="brand.500"
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    clearAlert();
                    setStatus((prevState) => ({
                      ...prevState,
                      showPassworForget: false,
                      showLogin: true,
                      showPasswordReset: false,
                    }));
                  }}
                >
                  Login instead
                </Text>
              </Flex>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                onClick={()=>{
                  handlePassword("/forgot-password");
                }}
                isLoading={loading}
              >
                Reset Password
              </Button>
            </FormControl>
          )}
          {status.showPasswordReset && (
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                Password<Text color="red.500">*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={status.showPassword ? "text" : "password"}
                variant="auth"
                name="password"
                onChange={handleInput}
              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                Confirm Password<Text color="red.500">*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={status.showPassword ? "text" : "password"}
                variant="auth"
                name="password_confirmation"
                onChange={handleInput}
              />
              <Flex justifyContent="space-between" align="center" mb="24px">
                <Text fontSize="sm" fontWeight="500">
                  All good?
                  <Link
                    ms="4px"
                    color="brand.500"
                    onClick={() => {
                        clearAlert();
                        setStatus((prevState) => ({
                          ...prevState,
                          showPassworForget: false,
                          showLogin: true,
                          showPasswordReset: false,
                        }));
                        history.push("/auth/login");
                    }}
                    _hover={{ cursor: "pointer" }}
                  >
                    Login instead
                  </Link>
                </Text>
              </Flex>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                isLoading={loading}
                onClick={()=>{
                  handlePassword("/reset-password");
                }}
              >
                Update Password
              </Button>
            </FormControl>
          )}
          {status.showLogin && (
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="8px"
              >
                Email<Text color="red.500">*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                mb="24px"
                fontWeight="500"
                size="lg"
                name="email"
                onChange={handleInput}
              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                Password<Text color="red.500">*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={status.showPassword ? "text" : "password"}
                  variant="auth"
                  name="password"
                  onChange={handleInput}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color="gray.400"
                    _hover={{ cursor: "pointer" }}
                    as={
                      status.showPassword
                        ? RiEyeCloseLine
                        : MdOutlineRemoveRedEye
                    }
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent="space-between" align="center" mb="24px">
                <Text fontSize="sm" fontWeight="500">
                  Forgot password?
                  <Link
                    ms="4px"
                    color="brand.500"
                    onClick={() => {
                      clearAlert();
                      setStatus((prevState) => ({
                        ...prevState,
                        showPassworForget: true,
                        showLogin: false,
                        showPasswordReset: false,
                      }));
                    }}
                    _hover={{ cursor: "pointer" }}
                  >
                    Reset password
                  </Link>
                </Text>
              </Flex>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                isLoading={loading}
                onClick={handleSubmit}
              >
                Sign In
              </Button>
            </FormControl>
          )}
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          ></Flex>
        </Flex>
      </Flex>
    </LayoutLogin>
  );
}

export default SignIn;
