import { Box, Input, Select, Textarea, Button, Icon, Flex, Text} from '@chakra-ui/react';
import React, { useState, useCallback } from 'react';
import { MdClose, MdUpload } from 'react-icons/md';
import Formfield from 'views/global/field';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AsyncSelect from 'react-select/async';
import { userController } from 'lib/user';
import SimpleUpload from "views/global/SimpleUpload";
import BeatLoader from "react-spinners/BeatLoader";

const FormGenerate = ({ fields, setFields, defaultValue }) => {

    const {dynamicRecord, attachmentUpload, deleteRecord, userDetail} = userController();
    const { getUser } = userDetail();
    const role = getUser.role;
    const [attachments, setAttachments] = useState(defaultValue.attachments ? defaultValue.attachments : []);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(Array(attachments.length).fill(false));
    
    
    const advanceGroup  = ["admin","office"];
    const roleGroup = !advanceGroup.includes(role) ? advanceGroup.concat(role) : advanceGroup;
    
    const [sections, setSections] = useState(() => {
        return fields
            .filter(field => field.type === "multiple")
            .map(field => ({
                id: field.name,
                fields: defaultValue && defaultValue[field.name] ? defaultValue[field.name] : []
            }));
    });

    const addField = useCallback((sectionId, additionalFields) => {
        setSections(prev => {
            const newSections = prev.map(section => {
                if (section.id === sectionId) {
                    const newFields = additionalFields.map(field => ({ [field]: "" }));
                    return { ...section, fields: [...section.fields, { ...Object.assign({}, ...newFields), id: `${sectionId}-${Math.random().toString(36).substring(7)}` }] };
                }
                return section;
            });
            return newSections;
        });
    }, []);

    const removeField = useCallback((sectionId, fieldIndex) => {
        setSections(prev => {
            const newSections = prev.map(section => {
                if (section.id === sectionId) {
                    return { ...section, fields: section.fields.filter((_, index) => index !== fieldIndex) };
                }
                return section;
            });
            return newSections;
        });

        setFields(prev => {
            if (!prev[sectionId]) {
                return prev;
            }
            const newFields = prev[sectionId].filter((_, index) => index !== fieldIndex);
            return { ...prev, [sectionId]: newFields };
        });
    }, [setFields]);

    const handleChange = useCallback((e, name) => {
        if (e === null) {
            setFields(prev => {
                const newFields = { ...prev };
                delete newFields[name];
                return newFields;
            });
            return;
        }
        if (Array.isArray(e)) {
            //save as seprated by comma
            const value = e.map(item => item.value).join(",");
            setFields(prev => ({ ...prev, [name]: value }));
            return;
        }
        const { value } = e.target ? e.target : e;
        setFields(prev => ({ ...prev, [name]: value }));
    }, [setFields]);

    const handleChangeMultiple = useCallback((e, name, sectionId, index) => {
        const newSections = sections.map(section => {
            if (section.id === sectionId) {
                section.fields[index][name] = e.target ? e.target.value : e.value;
            }
            return section;
        });
        setSections(newSections);

        setFields(prev => ({ ...prev, [sectionId]: newSections.find(section => section.id === sectionId).fields }));
    }, [sections, setFields]);

    const inputFieldType = ["text", "email", "password","date","hidden","datetime-local","number","tel","time","url"];
    const dateTypeFields = ["date","datetime-local"];

    const fieldValue = (sectionId, indexId, item) => {
        const row = sections.find((section) => section.id === sectionId).fields[indexId];
        if(row){
            if(row[item]){
                return row[item];
            }else{
                return false;
            }
        }else{
            return false;
        }
    };

    const formatDate = (InputDate, type) => {
        // Convert the Laravel date string to a JavaScript Date object
        const date = new Date(InputDate);
        // Format the date components
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = date.getDate().toString().padStart(2, '0');
        if(type === "date"){
            return `${year}-${month}-${day}`
        }else if(type === "datetime-local"){
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        }
    };

    const checkArrayData = (name, item) => {
        if(name.includes("__")){
            const splitName = name.split("__");
            if(splitName[0] in item && splitName[1] in item[splitName[0]]){
                return item[splitName[0]][splitName[1]];
            }
        }
    }

    return (
        <Box>
            {fields.map(field => (
                (advanceGroup.includes(role) || field.permissions?.includes(role)) && (
                    <Formfield
                        label={field.label}
                        help={field.help}
                        required={field.required && !(field.type === "password" && defaultValue["id"]) ? field.required : false}
                        display="block"
                        key={field.name}
                    >
                        {inputFieldType.includes(field.type) && (
                            <Input
                                type={field.type}
                                placeholder={field.placeholder ? field.placeholder : `Enter ${field.label}`}
                                name={field.name}
                                defaultValue={dateTypeFields.includes(field.type) && defaultValue[field.name] ? formatDate(defaultValue[field.name], field.type) : (field.split ? checkArrayData(field.name, defaultValue) : defaultValue[field.name])}
                                onChange={(e) => handleChange(e, field.name)}
                                key={field.name+"_"+defaultValue["id"]}
                                isRequired={field.required}
                            />
                        )}
                        {field.type === "divider" && (
                            <hr className='form_divider' />
                        )}
                        {field.type === "file" && (
                            <>
                            <SimpleUpload
                                w="100%"
                                minH="200px"
                                mb={attachments.length > 0 ? 5 : 0}
                                params={{accept: '.jpg,.png,.gif,.jpeg', multiple: true, maxSize: 5000000}}
                                key={field.name+"_"+defaultValue["id"]}
                                content={
                                    <>
                                    {loading ?
                                    <BeatLoader
                                    loading={true}
                                    size={15}
                                    aria-label="Loading Spinner"
                                    color="#422CFA"
                                    /> :
                                    <Box>
                                        <Icon as={MdUpload} w='42px' h='42px' color="brand.600" />
                                        <Flex justify='center' mx='auto' mb='12px'>
                                            <Text fontSize='lg' fontWeight='700' color="brand.600">
                                                Upload Files
                                            </Text>
                                        </Flex>
                                        <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                            Only images are allowed
                                        </Text>
                                    </Box> }
                                    </>
                                }
                                onUpload={(accepted, rejected)=>{
                                    if(rejected.length > 0){
                                        const errors = {};
                                        rejected.map((item, key) => {
                                            errors[key] = item.errors[0].message;
                                        });
                                        return;
                                    }
                                    if(accepted.length > 0){
                                        setLoading(true);
                                        let uploadCount = 0;
                                        const formData = new FormData();
                                        accepted.map((item, key) => {
                                            const uploadID      = defaultValue["id"] ? defaultValue["id"] : field.uploadID + process.env.REACT_APP_RANDOM_ID;
                                            setFields(prev => ({ ...prev, "attachment_id": uploadID}));
                                            formData.append(field.uploadKey, uploadID);
                                            formData.append('file', accepted[key]);
                                            attachmentUpload(field.uploadURL,formData).then((response) => {
                                                uploadCount++;
                                                setAttachments(prev => [...prev, response.data]);
                                                if (uploadCount === accepted.length) {
                                                    setLoading(false);
                                                }
                                            })
                                            formData.delete(field.uploadKey);
                                            formData.delete('file');
                                        });
                                    }
                                }}
                            />
                            <Box
                            display={attachments.length > 0 ? "block" : "none"}
                            >
                                {attachments.length > 0 && attachments.map((attachment, index) => (
                                    <Box
                                    key={index}
                                    className='attachment-preview'
                                    >
                                        <Box
                                        display="flex"
                                        justifyContent='center'
                                        alignItems='center'
                                        flexDirection='column'
                                        width='100%'
                                        height="100%"
                                        >
                                            {deleteLoading[index] ? (
                                                <BeatLoader
                                                loading={true}
                                                size={15}
                                                aria-label="Loading Spinner"
                                                color="#422CFA"
                                                />
                                            ) : (
                                            <>
                                            <Button
                                            variant="solid"
                                            colorScheme="red"
                                            size='xs'
                                            borderRadius="50%"
                                            p={0}
                                            my={1.5}
                                            ms={2}
                                            onClick={() => {
                                                const updatedLoading = [...deleteLoading]; // Copy the current loading state array
                                                updatedLoading[index] = true; // Set loading to true for the specific attachment being deleted
                                                setDeleteLoading(updatedLoading)
                                                deleteRecord(field.deleteURL,{id: attachment.id }).then(response => {
                                                    setAttachments(prev => prev.filter((_, i) => i !== index));
                                                }).finally(() => {
                                                    const updatedLoading = [...deleteLoading]; // Copy the current loading state array
                                                    updatedLoading[index] = false; // Set loading to false for the specific attachment being deleted
                                                    setDeleteLoading(updatedLoading); // Update the loading state array
                                                });
                                            }}
                                            >
                                                <MdClose />
                                            </Button>
                                            <img src={process.env.REACT_APP_URL+"/"+attachment.path} alt={attachment.name} />
                                            </>
                                        )}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            </>
                        )}
                        {field.type === "select" && (
                            <Select
                            id={field.name}
                            name={field.name}
                            required={field.required}
                            onChange={(e) => handleChange(e, field.name)}
                            key={field.name+"_"+defaultValue["id"]}
                            isRequired={field.required}
                            >
                            <option value="">Select {field.label}</option>
                            {field.options.map((option, optionIndex) => (
                                <option key={optionIndex} value={Array.isArray(option) ? option[1] : option} selected={defaultValue[field.name] === option}>
                                    {Array.isArray(option) ? option[0] : option}
                                </option>
                            ))}
                            </Select>
                        )}
                        {field.type === "dynamic-select" && (
                            <AsyncSelect
                            placeholder={`search ${field.placeholder}`}
                            loadOptions={(inputValue) => dynamicRecord(field.src, inputValue)}
                            key={field.name+"_"+defaultValue["id"]}
                            required={field.required}
                            onChange={(e) => handleChange(e, field.name)}
                            isRequired={field.required}
                            name={field.name}
                            isClearable={true}
                            isMulti={field.multiple}
                            inputId={field.name}
                            defaultValue={()=>{
                                if(!defaultValue[field.name]){
                                    return [];
                                }
                                if(!field.multiple){
                                    const baseKeyName = field.name.replace(/_id$/, '');
                                    return {
                                        label: defaultValue[baseKeyName].name ? defaultValue[baseKeyName].name : defaultValue[baseKeyName],
                                        value: defaultValue[baseKeyName].id ? defaultValue[baseKeyName].id : defaultValue[baseKeyName]
                                    };
                                }else{
                                    return defaultValue[field.name].map((item) => {
                                        return {
                                            label: item.name ? item.name : item,
                                            value: item.id ? item.id : item
                                        };
                                    });
                                }
                            }}
                            defaultOptions={defaultValue["id"] ? true : false}
                            />

                        )}
                        {field.type === "textarea" && (
                            <Textarea
                                placeholder={`Enter ${field.label}`}
                                name={field.name}
                                defaultValue={defaultValue[field.name]}
                                onChange={(e) => handleChange(e, field.name)}
                                key={field.name+"_"+defaultValue["id"]}
                                isRequired={field.required}
                            />
                        )}

                        {field.type === "editor" && (
                            <CKEditor
                            editor={ClassicEditor}
                            data={defaultValue[field.name]}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleChange({ target: { value: data } }, field.name);
                            }}
                            key={field.name+"_"+defaultValue["id"]}
                            isRequired={field.required}
                            config={{
                                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote','|', 'undo', 'redo', '|', 'insertTable'],
                                heading: {
                                    options: [
                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                                    ]
                                },
                                shouldNotGroupWhenFull: true,
                                image: {
                                    toolbar: []
                                }
                            }}
                        />
                        )}

                        {field.type === "multiple" && (
                            <>
                            {sections.find((section) => section.id === field.name).fields.map((sectionField, sectionFieldIndex) => (
                                <Box
                                key={sectionField.id}
                                display={field.design === "horizontal" ? "flex" : "block"}
                                justifyContent={field.design === "horizontal" ? "space-between" : "flex-start"}
                                mb={5}
                                >
                                    {field.multipleFields.map((addField) => {
                                        const defaultValueItem = fieldValue(field.name, sectionFieldIndex, addField.name) ? fieldValue(field.name, sectionFieldIndex, addField.name) : "";
                                        const defaultIDItem     = fieldValue(field.name, sectionFieldIndex, "id") ? fieldValue(field.name, sectionFieldIndex, "id") : addField.name+"-"+sectionFieldIndex;
                                        return (
                                            <Box
                                            me={field.design === "horizontal" ? 2 : 0}
                                            flexDirection={field.design === "horizontal" ? "row" : "column"}
                                            flex={field.design === "horizontal" ? "1" : "auto"}
                                            >
                                                {addField.type === "select" && (
                                                    <Select
                                                        width="100%"
                                                        id={addField.name}
                                                        name={addField.name}
                                                        onChange={(e) => handleChangeMultiple(e, addField.name, field.name, sectionFieldIndex)}
                                                        key={defaultIDItem}
                                                    >
                                                        <option value="">Select {addField.placeholder} </option>
                                                        {addField.options.map((option, optionIndex) => (
                                                            <option key={optionIndex} value={option} selected={defaultValueItem === String(option)}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                )}
                                                {inputFieldType.includes(addField.type) && (
                                                    <Input
                                                        width="100%"
                                                        type="text"
                                                        name={addField.name}
                                                        placeholder={`Enter ${addField.placeholder}`}
                                                        onChange={(e) => handleChangeMultiple(e, addField.name, field.name, sectionFieldIndex)}
                                                        key={defaultIDItem}
                                                        value={defaultValueItem}
                                                    />

                                                )}
                                            </Box>
                                        );
                                    })}
                                    <Button
                                        variant="outline"
                                        colorScheme="red"
                                        size='xs'
                                        borderRadius="50%"
                                        p={0}
                                        my={1.5}
                                        ms={2}
                                        onClick={() => removeField(field.name, sectionFieldIndex)}
                                    >
                                        <MdClose />
                                    </Button>
                                </Box>
                            ))}

                            <Button
                                variant="link"
                                colorScheme="brand"
                                onClick={() => {
                                    const fieldNames = field.multipleFields.map(f => f.name);
                                    addField(field.name, fieldNames);
                                }}
                            >
                                + Add New
                            </Button>
                            </>
                        )}
                    </Formfield>
                )
            ))}
        </Box>
    );
};
export default React.memo(FormGenerate);
