export const PropertyOptions = {
    exterior: [
        "Total Rooms",
        "Single Bed",
        "Double Bed",
        "Single Sofa Bed",
        "Double Sofa Bed",
        "Single Bunk Bed",
        "Double Bunk Bed",
        "Single Cot",
        "Double Cot",
        "Balcony",
        "Terrace",
        "Garden",
        "Pool",
        "Parking",
        "Garage",
        "Roof Terrace",
        "Kitchen",
        "Living Room",
        "Dining Room",
        "Bathroom",
        "Bathroom with Shower",
        "Storage",
        "Office",
        "Library",
        "Gym",
        "Sauna",
        "Jacuzzi",
        "Steam Room",
        "Cinema",
        "Games Room",
        "Bar",
        "Wine Cellar",
        "Laundry Room",
        "Utility Room",
    ],
    appliance: [
        "Air Conditioning",
        "TV",
        "Boiler",
        "Fridge",
        "Mini Fridge",
        "Freezer",
        "Oven",
        "Microwave",
        "Dishwasher",
        "Washing Machine",
        "Dryer",
        "Coffee Machine",
        "Kettle",
        "Toaster",
        "Iron",
        "Hair Dryer",
        "Vacuum Cleaner",
        "Heating",
        "Underfloor Heating",
        "Thermostat",
    ],
    facilities: [
        "Wi-fi Internet",
        "Curtains",
        "Kitchen",
        'Meter'
    ]
};
