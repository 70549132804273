import { React, useState} from 'react';
import {Box,Text,useDisclosure,Flex} from '@chakra-ui/react';
import SimpleSidebar from "views/global/SimpleSidebar";
import BoxDataTable from "views/global/BoxDataTable";
import SidebarScroll from 'components/scroll/SidebarScroll';
import { QuickAlert } from "theme/components/alerts";
import { MdDeleteOutline, MdEdit, MdList, MdLaptopChromebook, MdGroups, MdOutlineEvent, MdPauseCircle, MdCheckCircle,MdCancel,MdHouseSiding
} from "react-icons/md";
import handleSidebar from "lib/handleSidebar";
import { userController } from 'lib/user';
import FormGenerate from "views/global/FormGenerate";
import handleConfirmation from 'lib/handleConfirmation';


function ReservationTab(props) {

    const { id, title } = props;

    const { setAlert, clearAlert, showAlert } = QuickAlert();
    const {saveRecord, getSingleRecord, userDetail, deleteRecord} = userController();

    const [input, setInput] = useState(id ? {property_id: id} : null);

    const [status, setStatus] = useState({
        sidebarForm: false,
        loading: false,
        formLoading: false,
        saveNewList: false,
        editListEnabled: false,
        viewListEnabled: false,
        reservationTitle: "",
    });
    const trigerSaveList = status.saveNewList ? false : true;
    const fieldNames = [
        {label: "Name", name:"name", type:"text", required: true},
        {label: "Email", name:"email", type:"email", required: false},
        {label: "Contact Number", name:"number", type:"text", required: false},
        {label: "Check In", name:"check_in", type:"datetime-local", required: true},
        {label: "Check Out", name:"check_out", type:"datetime-local", required: true},
        {label: "Notes", name:"notes", type:"textarea", required: false},
        {label: "Adults", name:"adults", type:"select", required: false, options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]},
        {label: "Channel", name:"channel", type:"select", required: false, options: ['Airbnb', 'Booking']},
        {label: "Channel ID", name:"channel_reservation_id", type:"text", required: false},
        {label: "Refference", name:"ref", type:"text", required: false},
        {label: "Agency", name:"agency", type:"text", required: false},
        {label: "Total Amount", name:"total_amount", type:"text", required: false},
        {label: "Commission", name:"commission", type:"text", required: false},
        {label: "Extra", name:"extra", type:"text", required: false},
        {label: "Deposit", name:"deposit", type:"text", required: false},
        {label: "Status", name:"status", type:"select", required: false, options: ['pending','cancelled','confirmed']},
        {type: "divider"},
        {label: "City Tax Amount", name:"city_tax__amount", type:"text", required: false, split: true},
        {label: "City Tax Link", help:"Please leave it empty to automatic generate link using stripe.", name:"city_tax__link", type:"text", required: false, split: true},

    ]


    const checkboxMenu = [
        {
            icon: <MdDeleteOutline />,
            name: "Delete",
            color: "red",
            handle : (checked) => handleConfirmation(checked, "delete", "reservation", "Are you sure you want to delete selected reservation. This action cannot be undone.", "/user/reservation/delete",(response) => {
                clearAlert();
                setStatus(prevStatus => ({
                    ...prevStatus,
                    saveNewList: trigerSaveList,
                }));
                setAlert(response.data.status, response.data.message);
            },
            (error) => {
                setAlert("error",error.response.data.errors);
            }),
        }
    ];
    const tableMenuItems = [
        {
            icon: <MdEdit />,
            title: "Edit Reservation",
            handle : (row) => {
                setStatus(prevStatus => ({
                    ...prevStatus,
                    sidebarForm: true,
                    editListEnabled: true,
                    viewListEnabled: false,
                    formLoading: false
                }));
                setInput((prev) => ({
                    ...prev,
                    ...row,
                }));
                handleSidebar();
            },
        },
    ];

    const buttons = [
        {
            title: "Add Reservation",
            variant: "outline",
            color: "brand",
            icon: <MdList />,
            handle: () => {
                setStatus(prevStatus => ({
                    ...prevStatus,
                    sidebarForm: true,
                    editListEnabled: false,
                    viewListEnabled: false,
                }));
                setInput(id ? {property_id: id} : null);
                handleSidebar();


            }
        },
    ];

    const hideList = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            sidebarForm: false,
            editListEnabled: false,
            viewListEnabled: false,
            reservationTitle: "",
        }));
        setInput(id ? {property_id: id} : null);
        handleSidebar(false);
        clearAlert();
    }

    const saveListHandle = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            loading: true,
        }));
        saveRecord('/user/reservation/create',input).then(response => {
            return response;
        }).then(response => {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
                saveNewList: trigerSaveList,
            }));
            hideList();
        }).catch(function(error) {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
            }));
            setAlert("error",error.response.data.errors);
            SidebarScroll();
        });
    }

    return (
        <>
        <Box>
            {status.sidebarForm===false ? showAlert() : null }
            <BoxDataTable
                title={title}
                buttons={id ? buttons : false}
                src="/user/reservation/list"
                additionalOptions={id ? {property_id: id} : null}
                search={true}
                checkbox={id ? true : false}
                checkboxMenu={checkboxMenu}
                tableMenu={id ? tableMenuItems : false}
                trigger={status.saveNewList}
                design="dragable"
                dragableType="Reservation"
                defaultCalander="created_at"
                tableContent={(item)=>{
                    return (
                        <Box
                        cursor={"pointer"}
                        onClick={() => {
                            setStatus(prevStatus => ({
                                ...prevStatus,
                                sidebarForm: true,
                                viewListEnabled: true,
                                reservationTitle: item.name,
                                formLoading: false
                            }));
                            setInput((prev) => ({
                                ...prev,
                                ...item,
                            }));
                            handleSidebar();
                        }}
                        >
                            <Text className={item.status+" reservation-item-title"}>{item.name}</Text>
                            <Flex
                            display={{base:"block", md:"flex"}}
                            >
                                <Text className="muted-text d-flex d-icon-5" me={4}> <MdOutlineEvent/> {item.check_in} - {item.check_out}</Text>
                                <Text className="muted-text d-flex d-icon-5 d-icon-fx-18" me={4}><MdGroups/> {item.adults}</Text>
                                <Text className="muted-text d-flex d-icon-5" me={4}><MdLaptopChromebook/> {item.channel}</Text>
                                {!id && item.property && <Text className="muted-text d-flex d-icon-5 d-icon-fx-18"><MdHouseSiding/> {item.property.identifier}</Text>}
                            </Flex>
                        </Box>
                    )
                }}
            />
            <SimpleSidebar
            status={status.sidebarForm}
            title={status.viewListEnabled ? status.reservationTitle : (status.editListEnabled ? "Edit Reservation" : "Add Reservation")}
            save={{onClick:saveListHandle,isLoading:status.loading,text:status.editListEnabled ? "Update Reservation" : "Add Reservation"}}
            cancel={{onClick:hideList, text:"Cancel"}}
            loading={status.formLoading}
            viewOnly={status.viewListEnabled}
            >
                {status.sidebarForm===false ?  null : showAlert() }
                {status.sidebarForm===true && !status.viewListEnabled &&
                    <FormGenerate fields={fieldNames} setFields={setInput} defaultValue={input} />
                }
                {status.sidebarForm===true && status.viewListEnabled && input &&
                    <>
                    <Box
                    mb={2}
                    className='task-header-meta'
                    display={{base:"block", md:"flex"}}
                    justifyContent={{base:"center", md:"space-between"}}
                    alignItems={{base:"center", md:"center"}}
                    >
                        {input.check_in && input.check_out &&
                        <Text className='task-header-created-date task-header-item'>
                            <MdOutlineEvent/>
                            {input.check_in} - {input.check_out}
                        </Text>
                        }
                        {input.adults &&
                        <Text className='task-header-created-date task-header-item'>
                            <MdGroups/>
                            {input.adults}
                        </Text>
                        }
                        {input.channel &&
                        <Text className='task-header-created-date task-header-item'>
                            <MdLaptopChromebook/>
                            {input.channel}
                        </Text>
                        }
                        {input.status &&
                        <Text className={input.status+ ' task-header-status task-header-item'}>
                            {input.status==="confirmed" ? <MdCheckCircle/> : (input.status=="pending" ? <MdPauseCircle/> : <MdCancel/>)}
                            {input.status}
                        </Text>
                        }
                    </Box>
                    <Box
                    className='reservation-details'
                    display={{base:"block", md:"flex"}}
                    mb={2}
                    >
                        <Text width={{base:"100%", md:"50%"}}><span>Guest Email</span> {input.email ? input.email : "-"}</Text>
                        <Text><span>Guest Phone</span> {input.number ? input.number : "-"}</Text>
                    </Box>
                    <Box
                    className='reservation-details'
                    display={{base:"block", md:"flex"}}
                    mb={2}
                    >
                        <Text width={{base:"100%", md:"50%"}}><span>Refference</span> {input.ref ? input.ref : "-"}</Text>
                        <Text><span>Reservation ID</span> {input.channel_reservation_id ? input.channel_reservation_id : "-"}</Text>
                    </Box>
                    <Box
                    className='reservation-details'
                    display={{base:"block", md:"flex"}}
                    >
                        <Text width={{base:"100%", md:"50%"}}><span>Nights</span> {input.total_nights ? input.total_nights : "-"}</Text>
                        <Text><span>Agency</span> {input.agency ? input.agency : "-"}</Text>
                    </Box>
                    <Box
                    className='reservation-details'
                    display={{base:"block", md:"flex"}}
                    >
                        <Text width={{base:"100%", md:"50%"}}><span>City Tax Amount</span> {input.city_tax && input.city_tax.amount ?  input.city_tax.amount + " " +input.city_tax.currency : "-"}</Text>
                        <Text width={{base:"100%", md:"50%"}}><span>City Tax Link</span> {input.city_tax && input.city_tax.link && <a target='_blank' className='primary-link' href={input.city_tax.link}>{(() => {
                            const cleanedLink = input.city_tax.link.replace(/^https?:\/\/(www\.)?/, '');
                            return cleanedLink.length > 25 ? `${cleanedLink.substring(0, 25)}...` : cleanedLink;
                        })()}</a>}</Text>
                    </Box>
                    <Box
                    className='reservation-details'
                    >
                        {input.notes && <Text><span>Notes</span> {input.notes}</Text>}
                    </Box>
                    </>
                }
            </SimpleSidebar>
        </Box>
        </>

    );
}
export default ReservationTab;
