// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from 'lib/auth'

const PublicRoute = ({ component: Component, ...rest }) => {

  const isLoggedIn = useAuth().isAuthenticated();

  //return to login page if user is not authenticated
    return (
        <Route
        {...rest}
        render={(props) =>
            !isLoggedIn ? <Component {...props} /> : <Redirect to="/user" />
        }
        />
    );
}

export default PublicRoute;
